import React from "react";
import DefaultHead from "../DefaultHead";

const Home: React.FC = () => {
  return (
    <div>
      <DefaultHead />
      <h1>Home</h1>
      <p>There's no place like it.</p>
    </div>
  );
};

export default Home;
